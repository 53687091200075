import * as React from "react";

import Layout from "../components/Layout";
import { useStaticQuery, graphql } from "gatsby";

const LoginPage = () => {
  const data = useStaticQuery(graphql`
    query UsersQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "user" } } }
      ) {
        edges {
          node {
            frontmatter {
              name
              username
              password
            }
          }
        }
      }
    }
  `);

  var users = data.allMarkdownRemark.edges;

  users = users.map((user) => {
    return user.node.frontmatter;
  });

  const onLoginClick = (e) => {
    e.preventDefault();

    const username = document.getElementById("username").value;
    const password = document.getElementById("password").value;

    const user = users.find((user) => {
      return user.username === username && user.password === password;
    });

    if (user) {
      localStorage.setItem("details", JSON.stringify(user));
    } else {
      alert("Login failed; User not found.");
    }

    window.location.href = "/";
  };

  return (
    <Layout>
      <section className="contact section-padding">
        <div className="container">
          <div className="login row">
            <div className="col-lg-12">
              <form id="login-form">
                <div className="controls row">
                  <div className="col-lg-12">
                    <div className="form-group mb-30">
                      <label htmlFor="">Username</label>
                      <input
                        id="username"
                        type="text"
                        name="username"
                        placeholder="Enter Your Username"
                        required="required"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group mb-30">
                      <label htmlFor="">Password</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Enter Your Password"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-50">
                  <button
                    type="submit"
                    className="d-flex align-items-center"
                    onClick={onLoginClick}
                  >
                    <span className="text">Login</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="37"
                      height="36"
                      viewBox="0 0 37 36"
                      fill="none"
                    >
                      <path
                        d="M1 35L34.2929 1.70711C34.9229 1.07714 36 1.52331 36 2.41421V21.5H29.5"
                        stroke="#fff"
                        strokeWidth="2"
                      ></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LoginPage;
